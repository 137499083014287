<template>
  <div>
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Attached files</h3>
    <div
      v-if="files && files.length"
      class="wrap-span pb-4 md:pb-6 lg:pb-10"
    >
      <ul class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <li v-for="(file, key) in files" :key="key">
          <S3FileGenerator
            :document="file.name"
            :custom-class="'block font-normal text-base mt-2'"
          />
        </li>
      </ul>
    </div>
    <div
      v-else
      class="flex flex-wrap items-center justify-between wrap-span pt-5 pb-5"
    >
      <i>No files associated with this request</i>
    </div>
  </div>
</template>

<script>
import S3FileGenerator from "../../../../components/S3FileGenerator"

export default {
  components: {
    S3FileGenerator
  },
  props: {
    files: { type: Array }
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL
    };
  }
};
</script>
