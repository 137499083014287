<template>
  <div>
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Custom fields</h3>
    <div class="flex flex-wrap pb-4 md:pb-6 lg:pb-10">
      <div v-for="(customField, key) in customFieldArray" :key="key" class="lg:w-1/3 md:w-1/2 w-full mb-4 md:pr-6">
        <div :key="key + 0.3" v-if="customField.value" class="custom-fields">
          <div class="text-dark-blue text-base leading-tight mb-2">{{ customField.label }}</div>
          <div v-if="customField.type !== 'file'">
            <p class="mb-2 text-base leading-normal" v-if="customField.type === 'currency'"> $ {{ addZeroes(customField.value) }}</p>
            <p class="mb-2 text-base leading-normal" v-else-if="customField.type === 'number'">{{ addZeroes(customField.value) }}</p>
            <p class="mb-2 text-base leading-normal" v-else-if="customField.type === 'date'">{{ moment(customField.value) }}</p>
            <p class="mb-2 text-base leading-normal" v-else>{{ customField.value }}</p>
          </div>
          <div v-else>
            <S3FileGenerator :document="customField.value"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import S3FileGenerator from "../../../../components/S3FileGenerator";

export default {
  components: {
    S3FileGenerator
  },
  props: {
    customFields: { type: Array }
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      customFieldArray: [],
    }
  },
  methods: {
    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
  },
  mounted() {
    this.customFields.find((item) => {
      if (item.value) {
        this.customFieldArray.push(item);
      }
    });
  }
};
</script>