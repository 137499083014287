<template>
  <div>
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Legals</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 wrap-span pb-4 md:pb-6 lg:pb-10">
      <a class="w-max text-base" @click="goToLegals('privacy')"
        >Privacy Policy</a
      >
      <a class="w-max text-base" v-if="paymentRequestDetail.payNowUsedCard" @click="goToPayNowLegals()"
        >Terms & Conditions</a
      >

      <a class="w-max text-base" v-else @click="goToProductLegals()"
      >Terms & Conditions</a
      >
    </div>
  </div>
</template>

<script>
import {  mapMutations } from "vuex";
export default {
  name: "Legal",
  props: ["paymentRequestDetail"],
  data() {
    return {
      privacy: "",
      terms: "",
    };
  },
  methods: {
    ...mapMutations("paymentPlans", ["SET_CURRENT_PLAN"]),
    goToLegals(val) {
      this.terms = this.paymentRequestDetail.terms;
      this.privacy = this.paymentRequestDetail.privacy;
      const routeParams = { name: "terms-view",
        params: {
          id: val === "terms" ? this.terms : this.privacy
        },

        query: { type: "DASHBOARD" },

    }

      let routeData = this.$router.resolve(routeParams);
      window.open(routeData.href, "_blank");
    },
    goToPayNowLegals() {
      this.terms = this.paymentRequestDetail.terms;
      const routeParams = { name: "terms-view",
        params: {
          id: this.terms
        },
        query: { type: "CARD" },
    }

      let routeData = this.$router.resolve(routeParams);
      window.open(routeData.href, "_blank");
    },
    goToProductLegals() {
      this.terms = this.paymentRequestDetail.terms;
      const routeParams = {
        name: "pay-later-legal-view",
        query: { type: "PRODUCT-LEGAL", fullDocument: false },
        params: { id : this.terms},
    }

      let routeData = this.$router.resolve(routeParams);
      window.open(routeData.href, "_blank");
    },

  },
};
</script>
