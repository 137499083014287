<template>
  <div>
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Available collections actions</h3>
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 wrap-span pb-4 md:pb-6 lg:pb-10">
      <div v-if="paymentRequestDetail.requestOptions.includes('pay-later') && (paymentRequestDetail.paymentStatus === 'Active' || paymentRequestDetail.paymentStatus === 'Extended') && productConfigType == 'THREE_PARTY'">
        <h3 class="font-normal text-base">Customer portal</h3>
        <p class="mt-2">
          <a @click="redirectToCustomerPortal">View</a>
        </p>
      </div>
      <div v-if="paymentRequestDetail.selectedPaymentPlan && paymentRequestDetail.selectedPaymentPlan.maturityDate">
        <h3 class="font-normal text-base">Initial maturity date</h3>
        <p class="mt-2">{{ moment(paymentRequestDetail.selectedPaymentPlan.maturityDate) }}</p>
      </div>
    </div>
    <div class="grid grid-cols-1" v-if="paymentRequestDetail.requestOptions.includes('pay-later') && ['Active', 'Extended'].includes(paymentRequestDetail.paymentStatus)">
      <h3 class="font-normal text-base">Comms settings</h3>

      <div class="flex mb-4 mt-4" v-if="paymentRequestDetail.paymentPlan.creditDefaultCommunication.d1">
        <vs-switch v-model="paymentRequestDetail.creditDefaultCommunication.d1.enableComms" :disabled="getCommsSentAt(paymentRequestDetail.creditDefaultCommunication.d1.commsSentAt)" size="medium" @input="handleCommsSetting('d1')" />
        <p class="ml-4">End of plan demand for payment {{ getCommsSentAt(paymentRequestDetail.creditDefaultCommunication.d1.commsSentAt, "date") }}</p>
      </div>
      <div class="flex mb-4" v-if="paymentRequestDetail.paymentPlan.creditDefaultCommunication.d2">
        <vs-switch v-model="paymentRequestDetail.creditDefaultCommunication.d2.enableComms" size="medium" @input="handleCommsSetting('d2')" />
        <p class="ml-4">Weekly reminder of overdue balance {{ getCommsSentAt(paymentRequestDetail.creditDefaultCommunication.d2.commsSentAt, "date", "d2") }}</p>
      </div>
      <div class="flex mb-4" v-if="paymentRequestDetail.paymentPlan.creditDefaultCommunication.automatedCreditDefaultWarning && paymentRequestDetail.paymentPlan.creditDefaultCommunication.automatedCreditDefaultWarning.enableComms">
        <vs-switch v-model="paymentRequestDetail.creditDefaultCommunication.automatedCreditDefaultWarning.enableComms" size="medium" @input="handleCommsSetting('automatedCreditDefaultWarning')" />
        <p class="ml-4">Automated credit default warning</p>
      </div>
      <div class="flex mb-4" v-if="paymentRequestDetail.paymentPlan.creditDefaultCommunication.automatedSecurityInterestWarning && paymentRequestDetail.paymentPlan.creditDefaultCommunication.automatedSecurityInterestWarning.enableComms">
        <vs-switch v-model="paymentRequestDetail.creditDefaultCommunication.automatedSecurityInterestWarning.enableComms" size="medium" @input="handleCommsSetting('automatedSecurityInterestWarning')" />
        <p class="ml-4">Automated security interest warning</p>
      </div>
      <div class="flex mb-4" v-if="configureComms()">
        <vs-switch v-model="paymentRequestDetail.upcomingPaymentReminder.enableComms" size="medium" @input="handleCommsSetting('upcomingPaymentReminder')" />
        <p class="ml-4">Configurable reminder</p>
      </div>
      <div class="flex mb-4" v-if="paymentRequestDetail.paymentPlan.creditDefaultCommunication.configurableCheckIn && paymentRequestDetail.paymentPlan.creditDefaultCommunication.configurableCheckIn.enableComms">
        <vs-switch v-model="paymentRequestDetail.creditDefaultCommunication.configurableCheckIn.enableComms" size="medium" @input="handleCommsSetting('configurableCheckIn')" />
        <p class="ml-4">Configurable check-in</p>
      </div>
      <div class="grid md:grid-cols-2 sm:grid-cols-1">
        <div>
          <div class="flex">
            <vs-button class="mr-3" flat v-round size="medium" @click="securityInterestWarningPopUp = true">Warn of security interest lodgement</vs-button>
            <span class="pt-2">{{ getCommsSentAt(paymentRequestDetail.creditDefaultCommunication.automatedSecurityInterestWarning.commsSentAt, "date", "d4") }}</span>
          </div>
          <div class="flex mb-4 mt-4">
            <vs-button class="mr-3" flat v-round size="medium" @click="commsPopUp = true">Advise security interest lodged</vs-button>
            <span class="pt-2">{{ getCommsSentAt(paymentRequestDetail.creditDefaultCommunication.d4.commsSentAt, "date", "d4") }}</span>
          </div>
        </div>
        <div>
          <div class="flex">
            <vs-button class="mr-3" flat v-round size="medium" @click="creditDefaultWarningPopUp = true">Warn of credit default</vs-button>
            <span class="pt-2">{{ getCommsSentAt(paymentRequestDetail.creditDefaultCommunication.automatedCreditDefaultWarning.commsSentAt, "date", "d4") }}</span>
          </div>
          <div class="flex mb-4 mt-4">
            <vs-button class="mr-3" flat v-round size="medium" @click="creditDefaultLodgementPopUp = true">Advise credit default lodged</vs-button>
            <span class="pt-2">{{ getCommsSentAt(paymentRequestDetail.creditDefaultCommunication.creditDefaultLodgement.commsSentAt, "date", "d4") }}</span>
          </div>
        </div>
      </div>
    </div>

    <vs-popup title="Advise security interest lodged" header-icon="warning" class="close-icon-hidden custom-popup-decline" id="decline-popup" :active.sync="commsPopUp">
      <div class="w-full text-justify">
        <p class="flex mb-2">You're about to send communication to the borrower advising that we've lodged a security interest - make sure you've provided enough notice to the borrower and have actually lodged the security interest, before proceeding.</p>
      </div>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" @click="handleSecurityInterestLodgement" action-btn>Send comms</vs-button>
        <vs-button @click="commsPopUp = false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>

    <vs-popup title="Warn of credit default" header-icon="warning" class="close-icon-hidden custom-popup-decline" id="decline-popup" :active.sync="creditDefaultWarningPopUp">
      <div class="w-full text-justify">
        <p class="flex mb-2">You're about to send communication to the borrower warning them that we may lodge a credit default on their public record - make sure you've completed the necessary policy/regulatory steps before proceeding.</p>
      </div>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" @click="handleCreditDefaultWarning" action-btn>Send comms</vs-button>
        <vs-button @click="creditDefaultWarningPopUp = false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>

    <vs-popup title="Advise credit default lodged" header-icon="warning" class="close-icon-hidden custom-popup-decline" id="decline-popup" :active.sync="creditDefaultLodgementPopUp">
      <div class="w-full text-justify">
        <p class="flex mb-2">You're about to send communication to the borrower advising that we've lodged a credit default on their public record - make sure you've provided enough notice to the borrower and have actually lodged the default, before proceeding.</p>
      </div>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" @click="handleCreditDefaultLodgement" action-btn>Send comms</vs-button>
        <vs-button @click="creditDefaultLodgementPopUp = false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>

    <vs-popup title="Warn of security interest lodgement" header-icon="warning" class="close-icon-hidden custom-popup-decline" id="decline-popup" :active.sync="securityInterestWarningPopUp">
      <div class="w-full text-justify">
        <p class="flex mb-2">You're about to send communication to the borrower warning them that we may lodge a security interest on their asset</p>
      </div>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" @click="handleSecurityInterestWarning" action-btn>Send comms</vs-button>
        <vs-button @click="securityInterestWarningPopUp = false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import { sentryLog } from "../../../../../helper/sentryLog";

export default {
  props: {
    paymentRequestDetail: { type: Object },
    productConfigType: { type: String },
  },
  data() {
    return {
      commsPopUp: false,
      creditDefaultLodgementPopUp: false,
      creditDefaultWarningPopUp: false,
      securityInterestWarningPopUp: false,
    }
  },
  methods: {
    ...mapActions("paymentRequest", ["gotoCustomerPortal", "sendCreditLodgementComms"]),

    getCommsSentAt(date, returnType = "boolean", type = null) {
      if (!date) {
        return returnType == "date" ? "" : false;
      }

      const dateFormat = (type == "d4") ? "HH:mm DD/MM/YYYY" : "DD/MM/YYYY";

      return `(${ type === "d2" ? "latest sent" : "sent" } ${moment(date).format(dateFormat)})`;
    },

    redirectToCustomerPortal() {
      this.gotoCustomerPortal(this.paymentRequestDetail).then(res => {
        window.open(res.data.data.redirectUrl, "_blank");
      });
    },

    handleCommsSetting(commsType) {
      this.$emit("changeSettings", commsType);
    },

    moment(date) {
      return moment(date).format("DD MMM YYYY");
    },

    async handleSecurityInterestLodgement() {
      let data = {
        id: this.$route.params.id,
        commsType: "arrears-communication-d4"
      };
      this.$vs.loading();

      return await this.sendCreditLodgementComms(data).then((result) => {
        const commsSentTime = this.$lodashHelper.checkObjectKey(result.data.data, "creditDefaultCommunication.d4.commsSentAt", "");

        if (commsSentTime) {
          this.paymentRequestDetail.creditDefaultCommunication.d4.commsSentAt = commsSentTime;
        }

        this.commsPopUp = false;
        this.showToastMessage("Credit lodgement comms", "Comms has been sent.");
        this.$vs.loading.close();
      }).catch(ex => {
        sentryLog(ex);
        this.$vs.loading.close();
        this.commsPopUp = false;
        this.showToastMessage("Credit lodgement comms", "Something went wrong. Please try again later.", "error");
      });
    },

    configureComms() {
      return this.paymentRequestDetail.paymentPlan.upcomingPaymentReminder && this.paymentRequestDetail.paymentPlan.upcomingPaymentReminder.enableComms && ["B2C_BNPO", "B2C_BNPO_NC"].includes(this.paymentRequestDetail.selectedPaymentPlan.currentPlan.productType);
    },

    async handleCreditDefaultWarning() {
      let data = {
        id: this.$route.params.id,
        commsType: "credit-default-warning"
      };
      this.$vs.loading();

      return await this.sendCreditLodgementComms(data).then((result) => {
        const commsSentTime = this.$lodashHelper.checkObjectKey(result.data.data, "creditDefaultCommunication.automatedCreditDefaultWarning.commsSentAt", "");

        if (commsSentTime) {
          this.paymentRequestDetail.creditDefaultCommunication.automatedCreditDefaultWarning.commsSentAt = commsSentTime;
        }

        this.creditDefaultWarningPopUp = false;
        this.showToastMessage("Credit default warning comms", "Comms has been sent.");
        this.$vs.loading.close();
      }).catch(ex => {
        sentryLog(ex);
        this.$vs.loading.close();
        this.creditDefaultWarningPopUp = false;
        this.showToastMessage("Credit default warning comms", "Something went wrong. Please try again later.", "error");
      });
    },

    async handleCreditDefaultLodgement() {
      let data = {
        id: this.$route.params.id,
        commsType: "credit-default-lodgement"
      };
      this.$vs.loading();

      return await this.sendCreditLodgementComms(data).then((result) => {
        const commsSentTime = this.$lodashHelper.checkObjectKey(result.data.data, "creditDefaultCommunication.creditDefaultLodgement.commsSentAt", "");

        if (commsSentTime) {
          this.paymentRequestDetail.creditDefaultCommunication.creditDefaultLodgement = {
            commsSentAt : commsSentTime
          };
        }

        this.creditDefaultLodgementPopUp = false;
        this.showToastMessage("Credit default lodgement comms", "Comms has been sent.");
        this.$vs.loading.close();
      }).catch(ex => {
        sentryLog(ex);
        this.$vs.loading.close();
        this.creditDefaultLodgementPopUp = false;
        this.showToastMessage("Credit default lodgement comms", "Something went wrong. Please try again later.", "error");
      });
    },

    addPopUpClass(enabled) {
      if (enabled) {
        document.body.classList.add("custom-popup", "decline-popup");
      } else {
        document.body.classList.remove("custom-popup", "decline-popup");
      }
    },

    async handleSecurityInterestWarning() {
      let data = {
        id: this.$route.params.id,
        commsType: "security-interest-warning"
      };
      this.$vs.loading();

      return await this.sendCreditLodgementComms(data).then((result) => {
        const commsSentTime = this.$lodashHelper.checkObjectKey(result.data.data, "creditDefaultCommunication.automatedSecurityInterestWarning.commsSentAt", "");

        if (commsSentTime) {
          this.paymentRequestDetail.creditDefaultCommunication.automatedSecurityInterestWarning.commsSentAt = commsSentTime;
        }

        this.securityInterestWarningPopUp = false;
        this.showToastMessage("Security interest warning comms", "Comms has been sent.");
        this.$vs.loading.close();
      }).catch(ex => {
        sentryLog(ex);
        this.$vs.loading.close();
        this.securityInterestWarningPopUp = false;
        this.showToastMessage("Security interest warning comms", "Something went wrong. Please try again later.", "error");
      });
    },
  },
  watch: {
    commsPopUp() {
      this.addPopUpClass(this.commsPopUp);
    },
    creditDefaultWarningPopUp() {
      this.addPopUpClass(this.creditDefaultWarningPopUp);
    },
    creditDefaultLodgementPopUp() {
      this.addPopUpClass(this.creditDefaultLodgementPopUp);
    },
    securityInterestWarningPopUp() {
      this.addPopUpClass(this.securityInterestWarningPopUp);
    },
  },
};
</script>

