<template>
  <div>
    <vs-button v-if="isButton" flat v-round  @click="handleClickToGenerateLink" class="border-only-btn mt-3">Download data</vs-button>
    <div v-else-if="isText" class="anchor" :class="customClass" @click="handleClickToGenerateLink">Download</div>
    <div v-else class="anchor" :class="customClass" @click="handleClickToGenerateLink">{{ readableName() }}</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "S3FileGenerator",
  props: [
    "document",
    "customClass",
    "isObCsv",
    "isButton",
    "isText",
  ],
  data() {
    return {
      temporaryLinkUri: ""
    }
  },
  methods: {
    ...mapActions("s3ServiceModule", ["fetchFile"]),

    async handleClickToGenerateLink() {
      try {
        const s3Data = await this.fetchFile(this.document);
        if (s3Data && s3Data.hasOwnProperty("status") && s3Data.status === 200) {
          this.temporaryLinkUri = s3Data.data.data.url;
          window.open(this.temporaryLinkUri, "_blank");
        }
      } catch (ex) {}
    },

    readableName() {
      if (!this.document) {
        return;
      }

      try {
        const newName = this.document.split("/").pop();
        if (this.isObCsv === true) {
          return newName;
        } else {
          return newName.split("-").splice(1, newName.split('-').length).join("-");
        }
      } catch(ex) {}
    }
  }
}
</script>