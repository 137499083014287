<template>
  <div>
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Disbursement & payments</h3>
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 wrap-span pb-4 md:pb-6 lg:pb-10">
      <div class="mt-2" v-if="paymentRequestDetail.disbursement">
        <h3 class="font-normal text-base">We'll disburse to:</h3>
        <p class="mt-3">Acc name: {{ paymentRequestDetail.disbursement.accountName }}</p>
        <p class="mt-1">BSB: {{ paymentRequestDetail.disbursement.bsb }}</p>
        <p class="mt-1">Acc number: {{ paymentRequestDetail.disbursement.accountNumber }}</p>
        <p class="mt-1">Reference: {{ paymentRequestDetail.disbursement.reference }}</p>
      </div>
      <div class="mt-2" v-if="paymentRequestDetail.repayment">
        <h3 class="font-normal text-base">Direct payments/repayments to:</h3>
        <p class="mt-3" v-if="paymentRequestDetail.repayment.accountName">Acc name: {{ paymentRequestDetail.repayment.accountName }}</p>
        <p class="mt-1" v-if="paymentRequestDetail.repayment.bsb">BSB: {{ paymentRequestDetail.repayment.bsb }}</p>
        <p class="mt-1" v-if="paymentRequestDetail.repayment.accountNumber">Acc number: {{ paymentRequestDetail.repayment.accountNumber }}</p>
        <p class="mt-1" v-if="paymentRequestDetail.repayment.payId">PayID: {{ paymentRequestDetail.repayment.payId }}</p>
        <p class="mt-1" v-if="paymentRequestDetail.repayment.reference">Reference: {{ paymentRequestDetail.repayment.reference }}</p>
      </div>
      <div class="mt-2" v-if="paymentRequestDetail.debit">
        <h3 class="font-normal text-base">We'll auto-debit from:</h3>
        <p class="mt-3">Acc name: {{ paymentRequestDetail.debit.accountName }}</p>
        <p class="mt-1">BSB: {{ paymentRequestDetail.debit.bsb }}</p>
        <p class="mt-1">Acc number: {{ paymentRequestDetail.debit.accountNumber }}</p>
      </div>
      <div class="mt-2" v-else-if="isTwoParty && isAutoDebitDisabled && ['Active', 'Completed'].includes(paymentRequestDetail.paymentStatus)">
        <h3 class="font-normal text-base">We'll auto-debit from:</h3>
        <p class="mt-3">Note: Auto-debit not enabled, you must manually transfer funds on/before the scheduled repayment date</p>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 wrap-span pb-4 md:pb-6 lg:pb-10">
      <div v-if="paymentRequestDetail.disbursement">
        <h3 class="font-normal text-base" v-show="(paymentRequestDetail.disbursement.isExternal && paymentRequestDetail.refModel != 'Bank') || (paymentRequestDetail.refModel == 'MerchantSavedExternalAccount')">Note: this is a 3rd party account</h3>
      </div>
      <div v-if="paymentRequestDetail.repayment">
        <h3 class="font-normal text-base">Note: this is a {{ appName }} account</h3>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["paymentRequestDetail"],
    data() {
      return {
        appName: process.env.VUE_APP_NAME == "FlipPay" ? "Flip" : "Rello"
      }
    },
    computed: {
      isTwoParty() {
        return this.paymentRequestDetail.paymentPlan && this.paymentRequestDetail.paymentPlan.productConfigType == "TWO_PARTY";
      },

      isAutoDebitDisabled() {
        const autoDebit = this.paymentRequestDetail.paymentPlan && this.paymentRequestDetail.paymentPlan.autoDebit;
        return this.isTwoParty && !autoDebit;
      }
    }
  }
</script>

