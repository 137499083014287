<template>
  <div v-if="calculations">
    <div style="min-height:250px">
      <p>Please enter the new due date for this payment request. This action cannot be undone.</p>
      <div :class="showError ? 'hasError mt-8': 'mt-8'">
        <date-picker
          valueType="YYYY-MM-DD"
          type="date"
          lang="en"
          class="w-auto"
          format="DD-MM-YYYY"
          v-model="dueDate"
          @change="validateDate()"
          :disabled-days="disabledDate"
        ></date-picker>
      </div>
      <div v-if="showError" class="mt-2">
        <span class="text-red font-size-14">
          Date entered must be:<br>
          <li>in the future</li>
          <li>earlier than the current due date</li>
        </span>
      </div>
    </div>
    <div class="flex justify-end mt-4 md:mt-6 lg:mt-8 pt-1">
      <vs-button class="mr-8" @click="updateDueDate()" v-round size="large">Confirm</vs-button>
      <a @click="closePopUp()" class="text-xs flex items-center underline mid-blue">Cancel</a>
    </div>
  </div>
</template>

<script>
  import DatePicker from "vue2-datepicker";
  import { mapActions } from "vuex";
  import moment from "moment";

  export default {
    props: {
      selectedPaymentPlan: { type: Object },
      paymentRequestId: { type: String },
      accountId: { type: String },
      paymentRequestCustomId: { type: String },
      calculations: { type: Object },
      openingDate: { type: String },
    },
    components: {
      DatePicker,
    },

    data() {
      return {
        dueDate: "",
        showError: false,
        parentData: {},
      }
    },

    methods: {
      ...mapActions("paymentRequest", ["changeDueDate"]),

      validateDate() {
        this.showError = (!moment().isBefore(this.dueDate) || !moment(this.dueDate).isBefore(this.maxDate));
      },

      disabledDate(date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return date < today;
      },

      updateDueDate() {
        if (this.dueDate == "" || this.showError) {
          this.showError = true;
          return;
        }

        this.$vs.loading();
        const formattedDueDate = moment(this.dueDate).format("YYYY-MM-DD");
        const fullDueDate = new Date(formattedDueDate).toISOString();
        const dueDate = {
          maturityDate: fullDueDate,
          paymentRequestId: this.paymentRequestId,
        };
        this.changeDueDate(dueDate).then((response) => {
          this.closeModal(response.data.message, "success");
        }).catch((ex) => {
          this.closeModal(ex.response.data.message, "error");
        });
      },

      closeModal(message, type) {
        this.parentData.modalClose = true;
        this.dueDate = "";
        this.sendDataToParent(this.parentData);
        this.$vs.loading.close();
        this.showToastMessage(message, "", type);
      },

      closePopUp() {
        this.dueDate = "";
        this.showError = false;
        this.$emit("closePopUp");
      },

      sendDataToParent(parentData) {
        this.$emit("clicked", parentData);
      },

      momentDate(date) {
        return moment(date);
      }
    },

    computed: {
      maxDate() {
        return new Date(new Date(this.openingDate).getTime() + this.calculations.currentPlan.maxDuration * 24 * 3600 * 1000);
      }
    }
  }
</script>