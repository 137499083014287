<template>
  <div>
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">ID verification</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-4 md:gap-y-8 wrap-span pb-4 md:pb-6 lg:pb-8">
      <div v-for="(item, key) in idMatrixVerifications" :key="key" class="w-full">
        <label for=""> IDV attempt {{ key + 1 }}</label>
        <div class="mt-4 flex flex-row justify-between mb-8">
          <div class="flex flex-row gap-x-4">
            <label class="font-grey">{{ item.enquiryId }}</label>
            <vs-chip :color="item.overallOutcome == 'ACCEPT' ? 'success' : 'danger'" size="small">{{ item.overallOutcome }}</vs-chip>
          </div>
          <a @click="viewIdData(key)" v-if="!item.idv">View</a>
        </div>
        <div v-if="item.idv" class="grid gap-y-8">
          <div>
            <label>Full name</label><br>
            {{ item.idv.name }}
          </div>
          <div>
            <label>Date of birth</label><br>
            {{ formatDate(item.idv.dob) }}
          </div>
          <div>
            <label>Residential address</label><br>
            {{ item.idv.residentialAddress }}
          </div>
          <template v-if="item.idv.drivingLicence">
            <div>
              <label>Driver license number</label><br>
              {{ item.idv.drivingLicence.licenceNumber }}
            </div>
            <div>
              <label>State of issue</label><br>
              {{ item.idv.drivingLicence.state }}
            </div>
            <div>
              <label>Licence card number</label><br>
              {{ item.idv.drivingLicence.cardNumber }}
            </div>
          </template>
          <div v-if="item.idv.passportNumber">
            <label>Passport number</label><br>
            {{ item.idv.passportNumber }}
          </div>
          <div v-if="item.idv.issueDate">
            <label>Issue date</label><br>
            {{ formatDate(item.idv.issueDate) }}
          </div>
          <div>
            <label>Expiry date</label><br>
            {{ formatDate(item.idv.expiryDate) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions } from "vuex";
import moment from 'moment-timezone';

export default{
  props: ["paymentRequestDetail"],

  data() {
    return {
      idMatrixVerifications: [],
    }
  },

  mounted() {
    let idvData = JSON.parse(JSON.stringify(this.paymentRequestDetail.idMatrixVerifications));
    this.idMatrixVerifications = idvData.map(item => {
      item.idv = null;
      return item;
    })
  },

  methods: {
    ...mapActions("paymentRequest", ["fetchIdVerficationData"]),

    viewIdData(key) {
      this.$vs.loading();
      const payload = {
        _id: this.paymentRequestDetail._id,
        index: key
      }
      this.fetchIdVerficationData(payload).then((result) => {
        this.$vs.loading.close();
        this.idMatrixVerifications[key].idv = result.data.data;
      }).catch(ex => {
        this.$vs.loading.close();
        this.showToastMessage("Error", ex.response.data.message, "error");
      });
    },

    onCancel() {
      this.popupActive = false;
    },

    formatDate(date) {
      return date ? moment(date).format("DD MMM YYYY") : "N/A";
    }
  },
}
</script>
