<template>
  <vs-row>
    <vs-card class="mt-0" id="transactionWidget">
      <div slot="header">
        <div class="flex justify-between gap-x-4 md:gap-x-3">
          <h5 class="font-normal text-lg md:text-xl lg:text-2xl">Payments</h5>
        </div>
      </div>
      <div class="ds-wrapper">
        <div class="grid gap-y-1 payment-list" v-if="transactionBind">
          <vs-table :data="paymentTransactions" class="transaction-table">
            <colgroup>
              <col style="width: 15%;">
              <col style="width: 20%;">
              <col style="width: 10%;">
              <col style="width: 20%;">
              <col style="width: 25%;">
              <col style="width: 10%;">
            </colgroup>
            <template v-for="(transaction, index) in paymentTransactions">
              <vs-tr :key="index">
                <vs-td :data="transaction.dateFormatted">
                  {{ transaction.dateFormatted }}
                </vs-td>
                <vs-td :data="transaction.typeText">
                  <p class="text-body uppercase">{{ transaction.typeText }}</p>
                </vs-td>
                <vs-td :data="transaction.amountFormatted" colspan="2">
                  <p class="text-body">{{ transaction.amountFormatted }}</p>
                </vs-td>
                <vs-td :data="transaction.statusText" class="transaction-chip">
                  <vs-chip :color="getChipColor(transaction)" size="x-small" variant="outlined">{{ transaction.statusText }}</vs-chip>
                </vs-td>
                <vs-td :data="transaction.hasChild" class="transaction-toggle">
                  <div v-if="transaction.hasChild" class="cursor-pointer" @click="transaction.hasChild ? handleDisplayChild(index) : ''">
                    <vs-icon v-if="!transaction.showChild" icon="expand_more" class="text-body cursor-pointer expand"></vs-icon>
                    <vs-icon v-else icon="expand_less" class="text-body cursor-pointer expand"></vs-icon>
                  </div>
                </vs-td>
              </vs-tr>
              <template v-if="transaction.showChild">
                <template v-if="transaction.linkedTransactions && transaction.linkedTransactions.length">
                  <vs-tr :key="index+0.1" class="transaction-dotted"></vs-tr>
                  <vs-tr class="transaction-expand" :key="linkedKey+0.2" v-for="(linkedTransaction, linkedKey) in transaction.linkedTransactions">
                    <vs-td :data="linkedTransaction.dateFormatted">
                      {{ linkedTransaction.dateFormatted }}
                    </vs-td>
                    <vs-td>
                      <p :class="`text-${getChipColor(linkedTransaction)}-600 text-ancillary-caps uppercase`">{{ linkedTransaction.typeText }}</p>
                      <p class="text-body para-sm-light">{{ linkedTransaction.reference }} {{ getReferenceId(linkedTransaction) }}</p>
                    </vs-td>
                    <vs-td :data="linkedTransaction.amountFormatted">
                      <p :class="`text-${getChipColor(linkedTransaction)}-600`">{{ linkedTransaction.amountFormatted }}</p>
                    </vs-td>
                    <vs-td>
                      <div v-if="linkedTransaction.paymentMethodUsed && linkedTransaction.paymentMethodUsed.type == 'CARD'" class="flex items-center gap-x-4 md:gap-x-3">
                        <img :src="getCardType(linkedTransaction.paymentMethodUsed.card_type)" :alt="linkedTransaction.paymentMethodUsed.card_type" />
                        <p>
                          <span>**** {{ linkedTransaction.paymentMethodUsed.last_four_digits }}</span>
                          <span class="ml-4">{{ getExpiredYear({ month: linkedTransaction.paymentMethodUsed.month, year: linkedTransaction.paymentMethodUsed.year }) }}</span>
                        </p>
                      </div>
                      <div v-else-if="linkedTransaction.paymentMethodUsed && linkedTransaction.paymentMethodUsed.type == 'DIRECT_DEBIT'" class="flex items-center gap-x-4 md:gap-x-3">
                        <img :src="directDebitCard" alt="bank" />
                        <p>
                          <span>{{ maskBsb(linkedTransaction.paymentMethodUsed.bsb) }}</span>
                          <span class="ml-4">{{ linkedTransaction.paymentMethodUsed.accountNumber }}</span>
                        </p>
                      </div>
                      <div v-else-if="customerPaymentMethods && customerPaymentMethods.length == 1" class="flex items-center gap-x-4 md:gap-x-3">
                        <img v-if="defaultPaymentMethod.type == 'CARD'" :src="getCardType(defaultPaymentMethod.card_type)" :alt="defaultPaymentMethod.card_type" />
                        <img v-else :src="directDebitCard" alt="bank" />
                        <p v-if="defaultPaymentMethod.type == 'CARD'">
                          <span>**** {{ defaultPaymentMethod.last_four_digits }}</span>
                          <span class="ml-4">{{ getExpiredYear({ month: defaultPaymentMethod.month, year: defaultPaymentMethod.year }) }}</span>
                        </p>
                        <p v-else-if="defaultPaymentMethod.type == 'DIRECT_DEBIT'">
                          <span>{{ maskBsb(defaultPaymentMethod.bsb) }}</span>
                          <span class="ml-4">{{ defaultPaymentMethod.accountNumber }}</span>
                        </p>
                      </div>
                      <div v-else class="flex items-center gap-x-4 md:gap-x-3">
                        <img :src="linkedTransaction.icon" alt="direct-debit" />
                        <p class="text-body">Details not available at the moment</p>
                      </div>
                    </vs-td>
                    <vs-td>
                      <p class="text-body" v-if="displayFundsClearDate(linkedTransaction)">Expect to clear: {{ linkedTransaction.waitingOnClearedFundsDate }}</p>
                      <p class="text-body" v-else-if="linkedTransaction.provider == 'Till'">{{ linkedTransaction.providerReferenceText }}</p>
                    </vs-td>
                    <vs-td></vs-td>
                  </vs-tr>
                </template>
              </template>
            </template>
          </vs-table>
        </div>
      </div>
    </vs-card>
  </vs-row>
</template>
<script>

const amexCard = require("@/assets/images/cards/amex-small.svg");
const dinersClubCard = require("@/assets/images/cards/dinersclub-small.svg");
const directDebitCard = require("@/assets/images/cards/directdebit-small.svg");
const editIcon = require("@/assets/images/edit-pencil.svg");
const masterCard = require("@/assets/images/cards/mastercard-small.svg");
const unionpayCard = require("@/assets/images/cards/unionpay-small.svg");
const visaCard = require("@/assets/images/cards/visa-small.svg");

export default {
  props: {
    customerPaymentMethods: Array,
    transactions: Array,
  },

  data() {
    return {
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      directDebitCard: directDebitCard,
      editIcon: editIcon,
      masterCard: masterCard,
      paymentTransactions: [],
      unionpayCard: unionpayCard,
      visaCard: visaCard,
      transactionBind: false,
    };
  },

  methods: {
    getChipColor(transaction) {
      let color = "";

      switch(transaction.status) {
        case "Successful":
        case "Disbursed":
        case "Completed":
        case "Refunded":
          color = "success";
          break;
        case "Processing":
        case "Pending":
        case "Queued":
          color = "primary";
          break;
        case "Scheduled":
          color = "neutral";

          switch(transaction.subType) {
            case "Retry":
              color = "secondary";
              break;
            default:
              color = "neutral";
          }

          break;
        case "Applied":
        case "Failed":
        case "Missed":
          color = "error";
          break;
        default:
          color = "neutral";
          break;
      }

      return color;
    },

    getCardType(cardType) {
      let card = "";

      switch (cardType) {
        case "visa":
          card = this.visaCard;
          break;

        case "mastercard":
          card = this.masterCard;
          break;

        case "amexCard":
          card = this.amexCard;
          break;

        case "unionpay":
          card = this.unionunionpayCardpay;
          break;

        default:
          card = this.visaCard;
          break;
      }

      return card;
    },

    getPaymentTypeCard(paymentMethodUsed) {
      let card = "";

      if (paymentMethodUsed.type == "CARD") {
        card = this.getCardType(paymentMethodUsed.card_type);
      } else if (paymentMethodUsed.type == "DIRECT_DEBIT") {
        card = this.directDebitCard;
      }

      return card;
    },

    getExpiredYear(date) {
      let data = null;

      if (date && date.month !== undefined && date.year !== undefined) {
        data = `${date.month}/${date.year.substring(2, 4)}`;
      }

      return data;
    },

    handleDisplayChild(index) {
      for (let i = 0; i < this.paymentTransactions.length; i++) {
        if (index == i) {
          this.paymentTransactions[i].showChild = !this.paymentTransactions[i].showChild;
        } else if (this.paymentTransactions[i].hasChild) {
          this.paymentTransactions[i].showChild = false;
        }
      }
      this.$forceUpdate();
    },

    updateDetails() {
      this.transactionBind = false;
      this.paymentTransactions = JSON.parse(JSON.stringify(this.transactions));
      this.paymentTransactions = this.paymentTransactions.map(item => {
        item.hasChild = item.linkedTransactions && item.linkedTransactions.length;
        item.showChild = false;
        return item;
      });
      this.transactionBind = true;
    },

    maskBsb(string) {
      if (string.includes("-")) {
        return string;
      }

      let arr = string.replace(" ", "").split("");
      arr.splice(3, 0, "-");
      string = arr.join("");
      return string;
    },

    getReferenceId(transaction) {
      let data = "";

      if (transaction && transaction.provider == "Monoova" && transaction.providerReferenceText) {
        data = "  /  " + transaction.providerReferenceText;
      }

      return data;
    },

    displayFundsClearDate(transaction) {
      return ((transaction.status == "Processing") && (transaction.provider == "Monoova") && transaction.waitingOnClearedFundsDate);
    },
  },

  computed: {
    defaultPaymentMethod() {
      return this.customerPaymentMethods.find(item => item.default);
    },
  },

  mounted() {
    this.updateDetails();
  },

  watch: {
    transactions(val) {
      if (val) {
        this.updateDetails();
        const el = document.getElementById("transactionWidget");
        this.$scrollTo(el);
      }
    },
  }
};
</script>